<template>
  <div class="contact">
    <!-- <navi-gation /> -->
    <div class="contact_wrap" style="margin-left:60px;margin-top: 25px;">
      <div>
        <b>{{ $t("System.ContactPage.CorporateName") }}</b><span>{{obj.companyName}}</span>
      </div>
      <div>
        <b> {{ $t("System.ContactPage.CompanyAddress") }}</b><span>{{obj.companyAddress}}</span>
      </div>
      <div>
        <b> {{ $t("System.ContactPage.PostalCode") }}</b><span>{{obj.postcode}}</span>
      </div>
      <div>
        <b>{{ $t("System.ContactPage.Telephone") }}</b><span>{{obj.tel}}</span>
      </div>
      <div>
        <b>{{ $t("System.ContactPage.FaxNumber") }}</b><span>{{obj.facsimileNumber}}</span>
      </div>
      <div>
        <!--  -->
        <b>{{ $t("System.ContactPage.CompanyEmail") }}</b><span><a  href="#" @click="navigateToMail">{{obj.companyMail}}</a></span>
      </div>
      <div>
        <!--  -->
        <b>{{ $t("System.ContactPage.CompanyWebsite") }}</b><span><a  href="#" @click="navigateToWebsite">{{obj.website}}</a></span>
      </div>
      <div>
        <b>{{ $t("System.ContactPage.FreeConsultationHotline") }}</b><span>{{obj.freeHotline}}</span>
      </div>
    </div>

  </div>
</template>

<script>
import naviGation from '@/components/hearder/index'
export default {
  components: { naviGation },
  data() {
    return {
      descriptions: [],
      obj: infoConfig
    }
  },
  mounted() {
    // this.aboutInfo()
  },
  methods: {
    // async aboutInfo() {
    //   var params = {}
    //   const res = await this.$axios.post('/httpServe/about/info', params, true)
    //   // debugger
    //   this.descriptions = res.data
    //   console.log(res.data, 'res')
    // },
    navigateToMail() {
      // window.location.href =infoConfig.companyMail
      window.open(infoConfig.companyMail)//另外打开标签页
    },
    navigateToWebsite() {
      // window.location.href = infoConfig.website
      window.open(infoConfig.website)//另外打开标签页
    }
  }
}
</script>

<style lang='scss' scoped>
.contact {
  .contact_wrap {
    div {
      margin-bottom: 20px;
      b {
        font-size: 15px;
      }
      span {
        font-size: 14px;
        color: rgb(119, 119, 119);
      }
    }
  }
}
</style>